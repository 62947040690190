* {
  margin: 0;
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
  text-decoration: none;
}

.header {
  width: 100%;
  background-color: #232323;
  padding: 1rem;
  overflow: hidden;
  margin-bottom: 30px;
}

.header h1 {
  color: #fff;
  font-size: 2rem;
  float: left;
  margin: auto 30px;
  
}


@media screen and (max-width: 555px) {
  .header {
    margin-bottom: 10px;
  }
  .header h1 {
    margin: auto 0;
    width: 100%;
    text-align: center;
  }
  
}