@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #191919;
}

::-webkit-scrollbar-thumb {
  background: #8E61E2;
  border-radius: 5px;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  background-color: #191919;
  color: #fff;
}
footer {
    min-height: 80px;
    background: #232323;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 79px;  
}
  
footer p {
    line-height: 1.6;
    font-size: 1.1rem;
    color: #fff;
    text-align: center;
}

footer a {
    color: #8E61E2;
    text-decoration: none;
}
.search {
    background-color: transparent;
    border: 2px solid #8E61E2;
    border-radius: 5px;
    color: #fff;
    font-family: inherit;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    margin: 0px 30px 40px 30px;
    width: auto;
}
 
  
.search::-webkit-input-placeholder {
    color: rgb(139, 139, 139);
}
 
  
.search:-ms-input-placeholder {
    color: rgb(139, 139, 139);
}
 
  
.search::-ms-input-placeholder {
    color: rgb(139, 139, 139);
}
 
  
.search::placeholder {
    color: rgb(139, 139, 139);
}
  
.search:focus {
    background-color: #8E61E2;
    outline: none;
}
  
#content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
}

@media screen and (max-width: 910px) {
    .search {
        margin: 25px;
        width: calc(100% - 50px);
    }
    
    #content {
        grid-template-columns: repeat(1, 1fr);
    }
    
}
.p-container {
    position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}

.user-profile-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.profile-link {
	z-index: 3;
}

.user-profile {
    background-repeat: no-repeat;
    background-color: #8E61E2;
	background-size: cover;
	border-radius: 5px;
	box-shadow: 0 15px 55px rgba(0, 0, 0, 0.5);
	color: #eee;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	width: 300px;
	height: 300px;
	z-index: 3;
}

.user-profile h3 {
    margin: 0;
	background-color: rgba(255, 255, 255, 0.521);
    padding: 10px;
    width: 100%;
    text-align: center;
	letter-spacing: 5px;
	text-transform: uppercase;
	color: #000;
}

.user-profile p {
	font-weight: 300;
    letter-spacing: 1px;
    width: 100%;
    text-align: center;
    background-color: #8E61E2;
    border-radius: 0 0 5px 5px;
    padding: 6px;
}

.user-info {
	background-color: #fff;
	border-radius: 5px;
	box-shadow: 0 5px 55px rgba(0, 0, 0, 0.5);
    display: flex;
    width: 350px;
	justify-content: space-around;
	list-style-type: none;
	padding: 30px 0 15px;
    margin-top: -15px;
    margin-left: 30px;
    margin-right: 30px;
	text-align: center;
	z-index: 2;
}

.user-info a {
	text-decoration: none;
}

.user-info li {
	transition: all .2s ease-in-out;
}

.user-info li:hover {
	transform: scale(1.2);
}

.user-info h3 {
    margin: 0;
    color: #8E61E2;
}

.user-info h3 i{
	margin: 0;
	color: #8E61E2;
}

.user-info small {
	color: rgb(102, 102, 102);
	font-weight: 2em;
	font-size: 18px;
	margin: 0;
}

@media screen and (max-width: 910px) {
    .user-profile {
        width: 200px;
        height: 200px;
    }

    .user-info{
        width: 300px;
    }
    
}
#repos {
    max-height: calc(100vh - 354px);
    overflow-y: auto;
}

.repo-item {
    border-radius: 10px;
    padding: 30px;
    margin: 30px;
    background-color: #232323; 
    overflow: auto;
    overflow-wrap: break-word;
}

.img {
    margin-right: 5px;
    color: #8E61E2;
}

.description {
    color: white;
}
.repo-title {
    font-size: 30px;
    color: #8E61E2;
}

.bottom {
    position:relative;
}

.language, .star, .fork {
    max-width: 150px;
    display:inline-block;
    margin-right: 20px;
    color: white;
}

.repo-item:hover{
    text-decoration: none;
    background-color: #3b3b3b;
    transition: ease-in-out 0.2s;
}

.repo-item:hover > div > div{
    color: #8E61E2;
    transition: ease-in-out 0.2s;
}

a:hover{
    text-decoration: none;
}

@media screen and (max-width: 910px) {

    #repos {
        max-height: unset;
    }

}

* {
  margin: 0;
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
  text-decoration: none;
}

.header {
  width: 100%;
  background-color: #232323;
  padding: 1rem;
  overflow: hidden;
  margin-bottom: 30px;
}

.header h1 {
  color: #fff;
  font-size: 2rem;
  float: left;
  margin: auto 30px;
  
}


@media screen and (max-width: 555px) {
  .header {
    margin-bottom: 10px;
  }
  .header h1 {
    margin: auto 0;
    width: 100%;
    text-align: center;
  }
  
}
