@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;600&display=swap");

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #191919;
}

::-webkit-scrollbar-thumb {
  background: #8E61E2;
  border-radius: 5px;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  background-color: #191919;
  color: #fff;
}